const acolumns = [
  {
    title: 'No',
    slots: { customRender: 'no' },
  },
  {
    title: 'Kode UOM',
    dataIndex: 'code',
  },
  {
    title: 'Nama UOM',
    dataIndex: 'name',
  },
  // {
  //   title: 'Konversi Nilai',
  //   dataIndex: 'convert_idr_value',
  // },
  {
    title: 'Nama Program',
    dataIndex: 'program_name',
  },
  {
    title: 'Tipe Program',
    dataIndex: 'tipe_program',
  },
  {
    title: 'Paket Program',
    dataIndex: 'paket_program',
  },
  {
    title: 'Action',
    slots: { customRender: 'action' },
  },
]
export default acolumns
