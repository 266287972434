export default {
    code: {
        required: true,
        message: 'Kode jenis poin / UOM tidak boleh kosong!',
    },
    name: {
        required: true,
        message: 'Nama jenis poin / UOM tidak boleh kosong!',
    },
    program_id: {
        required: true,
        message: 'Program tidak boleh kosong!',
    },
    // tipe_program: {
    //     required: true,
    //     message: 'Tipe Program tidak boleh kosong!',
    // },
}
