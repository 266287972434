<template>
  <a-modal
    :visible="visible"
    :title="model.readOnly ? 'Lihat Jenis Poin / UOM' : form.id ? 'Edit Jenis Poin / UOM' : 'Tambah Jenis Poin / UOM'"
    @ok="handleOk"
    @cancel="handleCancel"
    width="1000px"
    :destroy-on-close="true"
  >
    <template #footer>
      <a-button key="back" :hidden="model.readOnly" @click="handleCancel">Cancel</a-button>
      <a-button
        key="submit"
        type="primary"
        :loading="loading"
        :hidden="model.readOnly"
        @click="handleOk"
        >Submit</a-button
      >
    </template>
    <a-form
      ref="formRef"
      class="myform"
      :model="form"
      :rules="rules"
      :wrapper-col="{ span: 14 }"
      :scroll-to-first-error="true"
    >
      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Kode"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            required
            has-feedback
            name="code"
          >
            <a-auto-complete
              v-model:value="code"
              :options="suggestCode"
              class="w-100"
              placeholder="Kode Jenis Poin / UOM"
              :filter-option="filterOption"
              :disabled="model.readOnly"
              @change="checkInput($event, 'code')"
            />
            <span class="text-danger" v-if="String(code).length > 50 && code != null">Maksimal 50 Karakter</span>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Deskripsi"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            has-feedback
            name="description"
          >
            <a-input
              placeholder="Deskripsi Jenis Poin / UOM"
              width="100%"
              v-model:value="description"
              :disabled="model.readOnly"
              maxlength="100"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Nama"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            required
            has-feedback
            name="name"
          >
            <a-auto-complete
              v-model:value="name"
              :options="suggestNama"
              class="w-100"
              placeholder="Nama Jenis Poin / UOM"
              :filter-option="filterOption"
              :disabled="model.readOnly"
              @change="checkInput($event, 'name')"
            />
            <!-- <filter-uom-name
              type="text"
              style="width: 100%"
              v-model:value="name"
              placeholder="Nama Jenis Poin / UOM"
              :disabled="model.readOnly"
              @change="setFilterNameValue"
            ></filter-uom-name> -->
          </a-form-item>
        </a-col>
      </a-row>

      <!-- <a-row class="form-row" v-show="isConvert">
        <a-col :sm="24">
          <a-form-item
            label="Convert (IDR)"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            name="convert_idr_value"
          >
            <a-input
              type="number"
              width="100%"
              v-model:value="convert_idr_value"
              :disabled="model.readOnly || disableConvert"
            />
            <span class="text-danger" v-show="isNegative">Nilai tidak boleh negatif</span>
          </a-form-item>
        </a-col>
      </a-row> -->

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Prefix / Suffix"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            :name="isPrefix ? 'prefix' : 'suffix'"
          >
            <a-radio-group style="width: 100%" :disabled="model.readOnly" v-model:value="isPrefix" @change="changeRadio">
              <a-radio :value="true">Prefix</a-radio>
              <a-radio :value="false">Suffix</a-radio>
            </a-radio-group>
            <a-input
              v-if="isPrefix"
              v-model:value="prefix"
              :disabled="true"
            />
            <a-input
              v-else
              v-model:value="suffix"
              :disabled="true"
            />
            <!-- <filter-uom-prefix
              v-if="isPrefix"
              class="mb-2"
              type="text"
              style="width: 100%"
              v-model:value="prefix"
              :disabled="model.readOnly || isPrefixDisable"
            ></filter-uom-prefix>
            <filter-uom-suffix
              v-else
              class="mb-2"
              type="text"
              style="width: 100%"
              v-model:value="suffix"
              :disabled="model.readOnly || isSuffixDisable"
            ></filter-uom-suffix> -->
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Program"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            name="program_id"
          >
            <filter-program
              type="text"
              style="width: 100%"
              v-model:value="program_id"
              placeholder="Pilih Program"
              :disabled="model.readOnly"
              :mode="null"
            ></filter-program>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Tipe Program"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            name="tipe_program"
          >
            <a-auto-complete
              v-model:value="tipe_program"
              :options="suggestTipeProgram"
              class="w-100"
              placeholder="Pilih Tipe Program"
              :filter-option="filterOption"
              :disabled="model.readOnly"
              @change="checkInput($event, 'tipe_program')"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Paket Program"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            name="tipe_program"
          >
            <a-auto-complete
              v-model:value="paket_program"
              :options="suggestPaketProgram"
              class="w-100"
              placeholder="Pilih Paket Program"
              :filter-option="filterOption"
              :disabled="model.readOnly"
              @change="checkInput($event, 'paket_program')"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Nama Filter"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            name="filter_name"
          >
            <a-input
              placeholder="Nama Filter Jenis Poin / UOM"
              v-model:value="filter_name"
              :disabled="true"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
  </a-modal>
</template>
<script>
import { defineComponent, reactive, toRef, toRefs, ref, onMounted, watch, methods } from 'vue'
import apiClient from '@/services/axios'
import { message } from 'ant-design-vue'
import { serialize } from 'object-to-formdata'
import rules from './rules'
import FilterProgram from '@/components/filter/FilterProgram'
// import FilterUomName from '@/components/filter/FilterUomName'
// import FilterUomPrefix from '@/components/filter/FilterUomPrefix'
// import FilterUomSuffix from '@/components/filter/FilterUomSuffix'
import { exit } from 'process'

export default defineComponent({
  components: {
    FilterProgram,
    // FilterUomName,
    // FilterUomPrefix,
    // FilterUomSuffix,
  },
  props: {
    visible: [Boolean],
    model: {
      type: [Object],
      default: () => ({
        id: null,
        code: null,
        description: null,
        name: null,
        convert_idr_value: null,
        prefix: null,
        suffix: null,
        tipe_program: null,
        paket_program: null,
        filter_name: null,
        access_clients_created_by: null,
        access_clients_updated_by: null,
        program_id: null,
        value_special_field_id_uom: null,
        created_at: null,
        created_by: null,
        updated_at: null,
        updated_by: null,
        is_deleted: false,
        record: {},
      }),
    },
  },
  emits: ['handleOk', 'update:visible'],
  setup(props, { emit }) {
    const form = reactive(Object.assign({}, props.model.record))
    const formRef = ref()
    const errorMessage = ref(null)
    const data = ref()
    const q = ref('')
    const suggestCode = reactive([])
    const suggestTipeProgram = reactive([])
    const suggestPaketProgram = reactive([])
    const suggestNama = reactive([])
    // const suggestPrefix = reactive([])
    // const suggestSuffix = reactive([])
    const isPrefix = ref(true)
    const disableConvert = ref(false)
    const state = reactive({
      isFetching: false,
    })
    const isNegative = ref(false)
    const isConvert = ref(true)
    const isPrefixDisable = ref(false)
    const isSuffixDisable = ref(false)

    let checkInput = (e, val) => {
      if (val == 'code') {
        if (e.length > 50) { form.code = e.substring(0,49) }
      }

      if (val == 'name') {
        if (e.length > 100) { form.name = e.substring(0,99) }
        setFilterNameValue()
      }

      if (val == 'tipe_program') {
        if (e.length > 20) { form.tipe_program = e.substring(0,19) }
      }

      if (val == 'paket_program') {
        if (e.length > 20) { form.paket_program = e.substring(0,19) }
      }
    }

    const fetchData = () => {
      apiClient
        .get('/api/filter/loyalty-uom')
        .then(response => {
          const items = response.data
          data.value = items

          suggestCode.splice(0)
          suggestTipeProgram.splice(0)
          suggestPaketProgram.splice(0)
          suggestNama.splice(0)
          // suggestPrefix.splice(0)
          // suggestSuffix.splice(0)

          data.value.forEach((value, index) => {
            // Suggest Code
            if (value.code != null) {
              if ( suggestCode.length <= 0) { suggestCode.push({value : value.code.toUpperCase()}) }
              else {
                var isExist = true
                var countExist = 0
                suggestCode.forEach((val, i) => {
                  if (value.code.toLowerCase().trim() !== val.value.toLowerCase().trim()) { isExist = false, countExist += 1 }
                  else { isExist = true }
                });
                if (isExist === false) { suggestCode.push({value : value.code.toUpperCase()})
                  if ((suggestCode.length - 1) !== countExist) { suggestCode.pop() }
                }
              }
            }
            // Suggest Tipe Program
            if (value.tipe_program != null) {
              if ( suggestTipeProgram.length <= 0) { suggestTipeProgram.push({value : value.tipe_program.toUpperCase()}) }
              else {
                var isExist = true
                var countExist = 0
                suggestTipeProgram.forEach((val, i) => {
                  if (value.tipe_program.toLowerCase().trim() !== val.value.toLowerCase().trim()) { isExist = false, countExist += 1 }
                  else { isExist = true }
                });
                if (isExist === false) { suggestTipeProgram.push({value : value.tipe_program.toUpperCase()})
                  if ((suggestTipeProgram.length - 1) !== countExist) { suggestTipeProgram.pop() }
                }
              }
            }
            // Suggest Paket Program
            if (value.paket_program != null) {
              if ( suggestPaketProgram.length <= 0) { suggestPaketProgram.push({value : value.paket_program.toUpperCase()}) }
              else {
                var isExist = true
                var countExist = 0
                suggestPaketProgram.forEach((val, i) => {
                  if (value.paket_program.toLowerCase().trim() !== val.value.toLowerCase().trim()) { isExist = false, countExist += 1 }
                  else { isExist = true }
                });
                if (isExist === false) { suggestPaketProgram.push({value : value.paket_program.toUpperCase()})
                  if ((suggestPaketProgram.length - 1) !== countExist) { suggestPaketProgram.pop() }
                }
              }
            }
            // Suggest Name
            if (value.name != null) {
              if ( suggestNama.length <= 0) { suggestNama.push({value : value.name.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase())}) }
              else {
                var isExist = true
                var countExist = 0
                suggestNama.forEach((val, i) => {
                  if (value.name.toLowerCase().trim() !== val.value.toLowerCase().trim()) { isExist = false, countExist += 1 }
                  else { isExist = true }
                });
                if (isExist === false) { suggestNama.push({value : value.name.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase())})
                  if ((suggestNama.length - 1) !== countExist) { suggestNama.pop() }
                }
              }
            }
            // Suggest Prefis
            // if (value.prefix != null) {
            //   if ( suggestPrefix.length <= 0) { suggestPrefix.push({value : value.prefix.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase())}) }
            //   else {
            //     var isExist = true
            //     var countExist = 0
            //     suggestPrefix.forEach((val, i) => {
            //       if (value.prefix.toLowerCase().trim() !== val.value.toLowerCase().trim()) { isExist = false, countExist += 1 }
            //       else { isExist = true }
            //     });
            //     if (isExist === false) { suggestPrefix.push({value : value.prefix.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase())})
            //       if ((suggestPrefix.length - 1) !== countExist) { suggestPrefix.pop() }
            //     }
            //   }
            // }
            // Suggest Suffix
            // if (value.suffix != null) {
            //   if ( suggestSuffix.length <= 0) { suggestSuffix.push({value : value.suffix.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase())}) }
            //   else {
            //     var isExist = true
            //     var countExist = 0
            //     suggestSuffix.forEach((val, i) => {
            //       if (value.suffix.toLowerCase().trim() !== val.value.toLowerCase().trim()) { isExist = false, countExist += 1 }
            //       else { isExist = true }
            //     });
            //     if (isExist === false) { suggestSuffix.push({value : value.suffix.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase())})
            //       if ((suggestSuffix.length - 1) !== countExist) { suggestSuffix.pop() }
            //     }
            //   }
            // }
          });
        })
        .finally(() => {
          // state.isFetching = false
        })
    }

    const handleOk = async () => {
      try {
        await formRef.value.validate()
      } catch (error) {
        return
      }

      form.code = String(form.code.toUpperCase())
      if (form.tipe_program) {
        form.tipe_program = String(form.tipe_program.toLowerCase())
      }
      if (form.paket_program) {
        form.paket_program = String(form.paket_program.toLowerCase())
      }
      if (isPrefix.value) {
        form.suffix = null
      } else {
        form.prefix = null
      }

      const form_data = serialize(
        {
          ..._.omit(form, [
            'is_deleted',
            'created_at',
            'updated_at',
            'created_by',
            'updated_by',
            'access_clients_created_by',
            'access_clients_updated_by',
          ]),
        },
        { indices: true },
      )

      const url = `/api/loyalty-point-uom${form.id ? '/' + form.id + '?_method=PUT' : ''}`
      apiClient
        .post(url, form_data)
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          message.success('Berhasil menyimpan')
          emit('handleOk', form, true)
        })
        .catch(({ response: { data, status } }) => {
          message.error(data.message)
          errorMessage.value = null
          // if (error.response) {
          //   const { status, statusText } = error.response
          //   const message = JSON.parse(await error.response.data.text()).message
          //   errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          // }
        })
    }

    const setFilterNameValue = () => {
      if (form.name.toLowerCase() != "poin" || form.name.toLowerCase() != "bola" || form.name.toLowerCase() != "rupiah") {
        form.filter_name = "Volume"
        form.suffix = "Ton"
      }
      if (form.name.toLowerCase() == "poin" || form.name.toLowerCase() == "bola" || form.name.toLowerCase() == "rupiah") {
        form.filter_name = form.name
        if (form.name.toLowerCase() == "rupiah") {
          form.prefix = "Rp"
        } else {
          form.suffix = form.name
        }
      }

      if (form.name.toLowerCase() != "poin" || form.name.toLowerCase() != "rupiah") {
        isConvert.value = false
      }
      if (form.name.toLowerCase() == "poin" || form.name.toLowerCase() == "rupiah") {
        isConvert.value = true
      }

      changeRadio()
    }

    const changeRadio = () => {
      if (form.name.toLowerCase() == "rupiah") {
        isPrefix.value = true
        isPrefixDisable.value = false
        isSuffixDisable.value = true
      }
      if (form.name.toLowerCase() != "rupiah") {
        isPrefix.value = false
        isSuffixDisable.value = true
        isSuffixDisable.value = false
      }
    }

    watch(() => {
      if (form.convert_idr_value < 0) {
        isNegative.value = true
      } else {
        isNegative.value = false
      }
    })

    onMounted(() => {
      if (form.prefix != undefined || form.prefix != null) {
        isPrefix.value = true
      }
      if (form.suffix != undefined || form.suffix != null) {
        isPrefix.value = false
      }
      fetchData()
    })

    const handleCancel = e => {
      emit('update:visible', false)
    }

    const filterOption = (input, option) => {
      return option.value.toUpperCase().indexOf(input.toUpperCase()) >= 0;
    }

    return {
      form,
      ...toRefs(form),
      emit,
      rules,
      data,
      q,
      fetchData,
      handleOk,
      handleCancel,
      formRef,
      errorMessage,
      suggestCode,
      suggestTipeProgram,
      suggestPaketProgram,
      suggestNama,
      // suggestPrefix,
      // suggestSuffix,
      isPrefix,
      disableConvert,
      filterOption,
      isNegative,
      setFilterNameValue,
      isConvert,
      isPrefixDisable,
      isSuffixDisable,
      changeRadio,
      checkInput,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
